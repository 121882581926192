.page_main__ibFHK {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 6rem;
  min-height: 100vh;
}

.page_description__s_Lqk {
  display: inherit;
  justify-content: inherit;
  align-items: inherit;
  font-size: 0.85rem;
  max-width: var(--max-width);
  width: 100%;
  z-index: 2;
  font-family: var(--font-mono);
}

.page_description__s_Lqk a {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.page_description__s_Lqk p, .page_description__s_Lqk button {
  cursor: pointer;
  position: relative;
  margin: 0;
  padding: 1rem;
  background-color: rgba(var(--callout-rgb), 0.5);
  border: 1px solid rgba(var(--callout-border-rgb), 0.3);
  border-radius: var(--border-radius);
}

.page_code__Cdcue {
  font-weight: 700;
  font-family: var(--font-mono);
}

.page_grid__2WZXq {
  display: grid;
  grid-template-columns: repeat(3, minmax(33%, auto));
  width: var(--max-width);
  max-width: 100%;
  margin-left: 50%
}

.page_card__ftWzl {
  padding: 1rem 1.2rem;
  border-radius: var(--border-radius);
  background: rgba(var(--card-rgb), 0);
  border: 1px solid rgba(var(--card-border-rgb), 0);
  transition: background 200ms, border 200ms;
}

.page_card__ftWzl span {
  display: inline-block;
  transition: transform 200ms;
}

.page_card__ftWzl h2 {
  font-weight: 600;
  margin-bottom: 0.7rem;
}

.page_card__ftWzl p {
  margin: 0;
  opacity: 0.6;
  font-size: 0.9rem;
  line-height: 1.5;
  max-width: 34ch;
}

.page_center__GvJ9Y {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 4rem 0;
}

.page_center__GvJ9Y::before {
  background: var(--secondary-glow);
  border-radius: 50%;
  width: 480px;
  height: 360px;
  margin-left: -400px;
}

.page_center__GvJ9Y::after {
  background: var(--primary-glow);
  width: 240px;
  height: 180px;
  z-index: -1;
}

.page_center__GvJ9Y::before,
.page_center__GvJ9Y::after {
  content: '';
  left: 50%;
  position: absolute;
  filter: blur(45px);
  transform: translateZ(0);
}

/* Enable hover only on non-touch devices */
@media (hover: hover) and (pointer: fine) {
  .page_card__ftWzl:hover {
    background: rgba(var(--card-rgb), 0.1);
    border: 1px solid rgba(var(--card-border-rgb), 0.15);
  }

  .page_card__ftWzl:hover span {
    transform: translateX(4px);
  }
}

/* Mobile and Tablet */
@media (max-width: 1023px) {
  .page_main__ibFHK {
    padding: 2rem;
  }

  .page_content__rFejU {
    padding: 4rem;
  }

  .page_grid__2WZXq {
    grid-template-columns: 1fr;
    margin-bottom: 120px;
    max-width: 320px;
    text-align: center;
    margin-left: 0;
  }

  .page_card__ftWzl {
    padding: 1rem 2.5rem;
  }

  .page_card__ftWzl h2 {
    margin-bottom: 0.5rem;
  }

  .page_center__GvJ9Y {
    padding: 8rem 0 6rem;
  }

  .page_center__GvJ9Y::before {
    transform: none;
    height: 300px;
  }

  .page_description__s_Lqk {
    font-size: 0.8rem;
  }

  .page_description__s_Lqk a {
    padding: 1rem;
  }

  .page_description__s_Lqk p,
  .page_description__s_Lqk div {
    display: flex;
    justify-content: center;
    position: fixed;
    width: 100%;
  }

  .page_description__s_Lqk p {
    align-items: center;
    inset: 0 0 auto;
    padding: 2rem 1rem 1.4rem;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid rgba(var(--callout-border-rgb), 0.25);
    background: linear-gradient(
      to bottom,
      rgba(var(--background-start-rgb), 1),
      rgba(var(--callout-rgb), 0.5)
    );
    background-clip: padding-box;
    -webkit-backdrop-filter: blur(24px);
            backdrop-filter: blur(24px);
  }

  .page_description__s_Lqk div {
    align-items: flex-end;
    pointer-events: none;
    inset: auto 0 0;
    padding: 2rem;
    height: 200px;
    background: linear-gradient(
      to bottom,
      transparent 0%,
      rgb(var(--background-end-rgb)) 40%
    );
    z-index: 1;
  }
}
/* cyrillic-ext */
@font-face {
  font-family: '__Inter_e7970e';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/d1d9458b69004127-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_e7970e';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/b967158bc7d7a9fb-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_e7970e';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/ae9ae6716d4f8bf8-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_e7970e';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/c0f5ec5bbf5913b7-s.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_e7970e';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/b1db3e28af9ef94a-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_e7970e';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/9c4f34569c9b36ca-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_e7970e';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/2aaf0723e720e8b9-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Inter_Fallback_e7970e';src: local("Arial");ascent-override: 90.00%;descent-override: 22.43%;line-gap-override: 0.00%;size-adjust: 107.64%
}.__className_e7970e {font-family: '__Inter_e7970e', '__Inter_Fallback_e7970e';font-style: normal
}

